export enum basketActionsTypes {
  SET_BASKET_REQUEST = 'SET_BASKET_REQUEST',
  SET_BASKET_SUCCESS = 'SET_BASKET_SUCCESS',
  SET_BASKET_FAILURE = 'SET_BASKET_FAILURE',

  GET_BASKET_REQUEST = 'GET_BASKET_REQUEST',
  GET_BASKET_SUCCESS = 'GET_BASKET_SUCCESS',
  GET_BASKET_FAILURE = 'GET_BASKET_FAILURE',
  RESET_BASKET_REQUEST = 'RESET_BASKET_REQUEST',

  SET_BASKET_DELIVERY_MODE_REQUEST = 'SET_BASKET_DELIVERY_MODE_REQUEST',
  SET_BASKET_DELIVERY_MODE_SUCCESS = 'SET_BASKET_DELIVERY_MODE_SUCCESS',
  SET_BASKET_DELIVERY_MODE_FAILURE = 'SET_BASKET_DELIVERY_MODE_FAILURE',

  SET_BASKET_DELIVERY_ADDRESS_REQUEST = 'SET_BASKET_DELIVERY_ADDRESS_REQUEST',
  SET_BASKET_DELIVERY_ADDRESS_SUCCESS = 'SET_BASKET_DELIVERY_ADDRESS_SUCCESS',
  SET_BASKET_DELIVERY_ADDRESS_FAILURE = 'SET_BASKET_DELIVERY_ADDRESS_FAILURE',

  SET_BASKET_ORDER_TYPE_REQUEST = 'SET_BASKET_ORDER_TYPE_REQUEST',
  SET_BASKET_ORDER_TYPE_SUCCESS = 'SET_BASKET_ORDER_TYPE_SUCCESS',
  SET_BASKET_ORDER_TYPE_FAILURE = 'SET_BASKET_ORDER_TYPE_FAILURE',

  RESET_BASKET_PAYMENT_METHODS = 'RESET_BASKET_PAYMENT_METHODS',

  ADD_MULTIPLE_PRODUCT_REQUEST = 'ADD_MULTIPLE_PRODUCT_REQUEST',
  ADD_MULTIPLE_PRODUCT_SUCCESS = 'ADD_MULTIPLE_PRODUCT_SUCCESS',
  ADD_MULTIPLE_PRODUCT_FAILURE = 'ADD_MULTIPLE_PRODUCT_FAILURE',

  UPDATE_MULTIPLE_PRODUCT_REQUEST = 'UPDATE_MULTIPLE_PRODUCT_REQUEST',
  UPDATE_MULTIPLE_PRODUCT_SUCCESS = 'UPDATE_MULTIPLE_PRODUCT_SUCCESS',
  UPDATE_MULTIPLE_PRODUCT_FAILURE = 'UPDATE_MULTIPLE_PRODUCT_FAILURE',

  ADD_PRODUCT_REQUEST = 'ADD_PRODUCT_REQUEST',
  ADD_PRODUCT_SUCCESS = 'ADD_PRODUCT_SUCCESS',
  ADD_PRODUCT_FAILURE = 'ADD_PRODUCT_FAILURE',

  REMOVE_PRODUCT_REQUEST = 'REMOVE_PRODUCT_REQUEST',
  REMOVE_PRODUCT_SUCCESS = 'REMOVE_PRODUCT_SUCCESS',
  REMOVE_PRODUCT_FAILURE = 'REMOVE_PRODUCT_FAILURE',

  UPDATE_PRODUCT_REQUEST = 'UPDATE_PRODUCT_REQUEST',
  UPDATE_PRODUCT_SUCCESS = 'UPDATE_PRODUCT_SUCCESS',
  UPDATE_PRODUCT_FAILURE = 'UPDATE_PRODUCT_FAILURE',

  ADD_UTENSILS_REQUEST = 'ADD_UTENSILS_REQUEST',
  ADD_UTENSILS_SUCCESS = 'ADD_UTENSILS_SUCCESS',
  ADD_UTENSILS_FAILURE = 'ADD_UTENSILS_FAILURE',

  REMOVE_UTENSILS_REQUEST = 'REMOVE_UTENSILS_REQUEST',
  REMOVE_UTENSILS_SUCCESS = 'REMOVE_UTENSILS_SUCCESS',
  REMOVE_UTENSILS_FAILURE = 'REMOVE_UTENSILS_FAILURE',
  GET_UTENSILS_PRODUCT_ID = 'GET_UTENSILS_PRODUCT_ID',

  GET_SINGLE_RESTAURANT_CALENDAR = 'GET_SINGLE_RESTAURANT_CALENDAR',
  GET_SINGLE_RESTAURANT_CALENDAR_SUCCESS = 'GET_SINGLE_RESTAURANT_CALENDAR_SUCCESS',
  GET_SINGLE_RESTAURANT_CALENDAR_FAILURE = 'GET_SINGLE_RESTAURANT_CALENDAR_FAILURE',

  UPDATE_BASKET_TIME_WANTED = 'UPDATE_BASKET_TIME_WANTED',
  UPDATE_BASKET_TIME_WANTED_SUCCESS = 'UPDATE_BASKET_TIME_WANTED_SUCCESS',
  UPDATE_BASKET_TIME_WANTED_FAILURE = 'UPDATE_BASKET_TIME_WANTED_FAILURE',

  DELETE_BASKET_TIME_WANTED = 'DELETE_BASKET_TIME_WANTED',
  DELETE_BASKET_TIME_WANTED_SUCCESS = 'DELETE_BASKET_TIME_WANTED_SUCCESS',
  DELETE_BASKET_TIME_WANTED_FAILURE = 'DELETE_BASKET_TIME_WANTED_FAILURE',

  UPDATE_BASKET_TIP_AMOUNT = 'UPDATE_BASKET_TIP_AMOUNT',
  UPDATE_BASKET_TIP_AMOUNT_SUCCESS = 'UPDATE_BASKET_TIP_AMOUNT_SUCCESS',
  UPDATE_BASKET_TIP_AMOUNT_FAILURE = 'UPDATE_BASKET_TIP_AMOUNT_FAILURE',
  NO_TIP_SELECTED = 'NO_TIP_SELECTED',

  UPDATE_BASKET_COUPON_CODE = 'UPDATE_BASKET_COUPON_CODE',
  UPDATE_BASKET_COUPON_CODE_SUCCESS = 'UPDATE_BASKET_COUPON_CODE_SUCCESS',
  UPDATE_BASKET_COUPON_CODE_FAILURE = 'UPDATE_BASKET_COUPON_CODE_FAILURE',

  REMOVE_BASKET_COUPON_CODE = 'REMOVE_BASKET_COUPON_CODE',
  REMOVE_BASKET_COUPON_CODE_SUCCESS = 'REMOVE_BASKET_COUPON_CODE_SUCCESS',
  REMOVE_BASKET_COUPON_CODE_FAILURE = 'REMOVE_BASKET_COUPON_CODE_FAILURE',

  VALIDETE_BASKET = 'VALIDETE_BASKET',
  VALIDETE_BASKET_SUCCESS = 'VALIDETE_BASKET_SUCCESS',
  VALIDETE_BASKET_FAILURE = 'VALIDETE_BASKET_FAILURE',
  VALIDETE_BASKET_PHONE_FAILURE = 'VALIDETE_BASKET_PHONE_FAILURE',

  SUBMIT_BASKET_MULTIPLE_PAYMENT = 'SUBMIT_BASKET_MULTIPLE_PAYMENT',
  SUBMIT_BASKET_MULTIPLE_PAYMENT_SUCCESS = 'SUBMIT_BASKET_MULTIPLE_PAYMENT_SUCCESS',
  SUBMIT_BASKET_MULTIPLE_PAYMENT_FAILURE = 'SUBMIT_BASKET_MULTIPLE_PAYMENT_FAILURE',

  CREATE_BASKET_FROM_PREV = 'CREATE_BASKET_FROM_PREV',
  CREATE_BASKET_FROM_PREV_SUCCESS = 'CREATE_BASKET_FROM_PREV_SUCCESS',
  CREATE_BASKET_FROM_PREV_FAILURE = 'CREATE_BASKET_FROM_PREV_FAILURE',

  CREATE_BASKET_FROM_PREV_ORDER_REQUEST = 'CREATE_BASKET_FROM_PREV_ORDER_REQUEST',
  CREATE_BASKET_FROM_PREV_ORDER_SUCCESS = 'CREATE_BASKET_FROM_PREV_ORDER_SUCCESS',
  CREATE_BASKET_FROM_PREV_ORDER_FAILURE = 'CREATE_BASKET_FROM_PREV_ORDER_FAILURE',

  CREATE_BASKET_FROM_FAV_ORDER_REQUEST = 'CREATE_BASKET_FROM_FAV_ORDER_REQUEST',
  CREATE_BASKET_FROM_FAV_ORDER_SUCCESS = 'CREATE_BASKET_FROM_FAV_ORDER_SUCCESS',
  CREATE_BASKET_FROM_FAV_ORDER_FAILURE = 'CREATE_BASKET_FROM_FAV_ORDER_FAILURE',

  BASKET_TRANSFER_REQUEST = 'BASKET_TRANSFER_REQUEST',
  BASKET_TRANSFER_SUCCESS = 'BASKET_TRANSFER_SUCCESS',
  BASKET_TRANSFER_FAILURE = 'BASKET_TRANSFER_FAILURE',
  BASKET_TRANSFER_RESET = 'BASKET_TRANSFER_RESET',

  ADD_BASKET_ORDER_SUBMIT = 'ADD_BASKET_ORDER_SUBMIT',
  REMOVE_BASKET_ORDER_SUBMIT = 'REMOVE_BASKET_ORDER_SUBMIT',

  GET_UPSELLS_REQUEST = 'GET_UPSELLS_REQUEST',
  GET_UPSELLS_REQUEST_SUCCESS = 'GET_UPSELLS_REQUEST_SUCCESS',
  GET_UPSELLS_REQUEST_FAILURE = 'GET_UPSELLS_REQUEST_FAILURE',

  ADD_UPSELLS_REQUEST = 'ADD_UPSELLS_REQUEST',
  ADD_UPSELLS_REQUEST_RESET = 'ADD_UPSELLS_REQUEST_RESET',
  ADD_UPSELLS_REQUEST_SUCCESS = 'ADD_UPSELLS_REQUEST_SUCCESS',
  ADD_UPSELLS_REQUEST_FAILURE = 'ADD_UPSELLS_REQUEST_FAILURE',

  GET_BASKET_ALLOWED_CARDS_REQUEST = 'GET_BASKET_ALLOWED_CARDS_REQUEST',
  GET_BASKET_ALLOWED_CARDS_REQUEST_SUCCESS = 'GET_BASKET_ALLOWED_CARDS_REQUEST_SUCCESS',
  GET_BASKET_ALLOWED_CARDS_REQUEST_FAILURE = 'GET_BASKET_ALLOWED_CARDS_REQUEST_FAILURE',

  UPDATE_BASKET_BILLING_SCHEMES = 'UPDATE_BASKET_BILLING_SCHEMES',
  UPDATE_DUPLICATE_ADDRESS = 'UPDATE_DUPLICATE_ADDRESS',

  NAVIGATION_URL = 'NAVIGATION_URL',

  GET_USER_DELIVERY_ADDRESSES_FOR_BASKET = 'GET_USER_DELIVERY_ADDRESSES_FOR_BASKET',
  GET_USER_DELIVERY_ADDRESSES_FOR_BASKET_SUCCESS = 'GET_USER_DELIVERY_ADDRESSES_FOR_BASKET_SUCCESS',
  GET_USER_DELIVERY_ADDRESSES_FOR_BASKET_FAILURE = 'GET_USER_DELIVERY_ADDRESSES_FOR_BASKET_FAILURE',

  VALIDATE_AND_GET_BASKET_REQUEST = 'VALIDATE_AND_GET_BASKET_REQUEST',

  CHARITY_ALLOWED_REQUEST = 'CHARITY_ALLOWED_REQUEST',
  CHARITY_ALLOWED_REQUEST_SUCCESS = 'CHARITY_ALLOWED_REQUEST_SUCCESS',
  CHARITY_ALLOWED_REQUEST_FAILURE = ' CHARITY_ALLOWED_REQUEST_FAILURE',

  SET_CHARITY_CHECKBOX_STATE = 'SET_CHARITY_CHECKBOX_STATE',

  SHOW_PROMO_MESSAGE = 'SHOW_PROMO_MESSAGE',
  SHOW_PROMO_DIALOG_MESSAGE = 'SHOW_PROMO_DIALOG_MESSAGE',

  SUCCESS_MESSAGE_CREATE_BASKET='SUCCESS_MESSAGE_CREATE_BASKET',

  ADD_PAYMENT_METHOD_INFORMATION = 'ADD_PAYMENT_METHOD_INFORMATION',
  SET_SAVE_CREDIT_CARD = 'SET_SAVE_CREDIT_CARD',
  ADD_DIGITAL_PAYMENT_METHOD_INFORMATION='ADD_DIGITAL_PAYMENT_METHOD_INFORMATION'
}
