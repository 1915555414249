import { takeEvery, put, call } from 'redux-saga/effects';
import { basketActionsTypes } from '../../../types/basket';

import { getRestaurantCalendar } from '../../../../services/restaurant/calendar';
import {
  setTimeWantedBasket,
  deleteTimeWantedBasket,
  setTipAmountBasket,
  applyCouponBasket,
  validateBasket,
  getBasketAllowedCards,
  removeCouponBasket,
  submitMultiplePaymentBasket,
} from '../../../../services/checkout';
import {
  getSingleRestaurantCalendarSuccess,
  getSingleRestaurantCalendarFailure,
  updateBasketTimeWantedSuccess,
  updateBasketTimeWantedFailure,
  deleteBasketTimeWantedSuccess,
  deleteBasketTimeWantedFailure,
  updateBasketTipAmountSuccess,
  updateBasketTipAmountFailure,
  updateBasketCouponCodeSuccess,
  updateBasketCouponCodeFailure,
  removeBasketCouponCodeSuccess,
  removeBasketCouponCodeFailure,
  validateBasketSuccess,
  validateBasketFailure,
  validateBasketPhoneFailure,
  setBasketDeliveryModeSuccess,
  setBasketDeliveryModeFailure,
  setBasketDeliveryAddressSuccess,
  setBasketDeliveryAddressFailure,
  getBasketAllowedCardsRequestSuccess,
  getBasketAllowedCardsRequestFailure,
  getCharityRequestFailure,
  getCharityRequestSuccess,
  submitBasketMultiplePaymentSuccess,
  submitBasketMultiplePaymentFailure,
} from '../../../actions/basket/checkout';

import {
  getBasket,
  getCharityRequest,
  setBasketCustomFields,
  setBasketDeliveryAddress,
  setBasketDeliveryMode,
} from '../../../../services/basket';
import { getBasketRequestSuccess } from '../../../actions/basket';

function* asyncgetSingleRestaurantCalendarRequest(action: any): any {
  try {
    const response = yield call(
      getRestaurantCalendar,
      action.id,
      action.dateFrom,
      action.dateTo,
    );
    yield put(getSingleRestaurantCalendarSuccess(response));
  } catch (error) {
    yield put(getSingleRestaurantCalendarFailure(error));
  }
}

function* asyncUpdateBasketTimeWanted(action: any): any {
  try {
    const response = yield call(
      setTimeWantedBasket,
      action.basketId,
      action.data,
    );
    yield put(updateBasketTimeWantedSuccess(response));
    yield put({
      type: basketActionsTypes.VALIDATE_AND_GET_BASKET_REQUEST,
      action,
    });
    action.successCallback?.();
  } catch (error) {
    yield put(updateBasketTimeWantedFailure(error));
  }
}

function* asyncDeleteBasketTimeWanted(action: any): any {
  try {
    const response = yield call(deleteTimeWantedBasket, action.basketId);
    yield put(deleteBasketTimeWantedSuccess(response));
    yield put({
      type: basketActionsTypes.VALIDATE_AND_GET_BASKET_REQUEST,
      action,
    });
  } catch (error) {
    yield put(deleteBasketTimeWantedFailure(error));
  }
}

function* asyncUpdateBasketTipAmount(action: any): any {
  try {
    const response = yield call(
      setTipAmountBasket,
      action.basketId,
      action.data,
    );
    yield put(updateBasketTipAmountSuccess(response));
    yield put({
      type: basketActionsTypes.VALIDATE_AND_GET_BASKET_REQUEST,
      action,
    });
  } catch (error) {
    yield put(updateBasketTipAmountFailure(error));
  }
}

function* asyncUpdateBasketCouponCode(action: any): any {
  try {
    const response = yield call(
      applyCouponBasket,
      action.basketId,
      action.data,
    );
    yield put(updateBasketCouponCodeSuccess(response));
    yield put({
      type: basketActionsTypes.VALIDATE_AND_GET_BASKET_REQUEST,
      action,
    });
  } catch (error) {
    yield put(updateBasketCouponCodeFailure(error));
  }
}

function* asyncRemoveBasketCouponCode(action: any): any {
  try {
    const response = yield call(removeCouponBasket, action.basketId);
    yield put(removeBasketCouponCodeSuccess(response));
    yield put({
      type: basketActionsTypes.VALIDATE_AND_GET_BASKET_REQUEST,
      action,
    });
  } catch (error) {
    yield put(removeBasketCouponCodeFailure(error));
  }
}

function* asyncSetBasketDeliveryModeRequest(action: any): any {
  try {
    const response = yield call(
      setBasketDeliveryMode,
      action.action.payload.basketId,
      action.action.payload.deliverymode,
    );
    yield put(setBasketDeliveryModeSuccess(response));
  } catch (error) {
    yield put(setBasketDeliveryModeFailure(error));
  }
}

function* asyncSetBasketDeliveryAddressRequest(action: any): any {
  try {
    const response = yield call(
      setBasketDeliveryAddress,
      action.basketid,
      action.payload,
    );
    yield put(setBasketDeliveryAddressSuccess(response));
  } catch (error) {
    yield put(setBasketDeliveryAddressFailure(error));
  }
}

function* asyncValidateBasket(action: any): any {
  try {
    if (action.basketPayload) {
      yield put({
        type: basketActionsTypes.ADD_BASKET_ORDER_SUBMIT,
      });
    }

    const validateResponse = yield call(validateBasket, action.basketId);
    yield put(validateBasketSuccess(validateResponse));
    if (action.onSuccess) {
      action.onSuccess();
    }

    // const basketResponse = yield call(getBasket, action.basketId);
    // yield put(getBasketRequestSuccess(basketResponse));
  } catch (error: any) {
    yield put({
      type: basketActionsTypes.REMOVE_BASKET_ORDER_SUBMIT,
    });
    if (error?.config?.url && error?.config?.url?.includes('api/auth/users')) {
      yield put(validateBasketPhoneFailure(error));
    } else {
      yield put(validateBasketFailure(error));
    }
  }
}

function* asyncSubmitBasketMultiplePayment(action: any): any {
  try {
    const response = yield call(
      submitMultiplePaymentBasket,
      action.basketId,
      action.data,
    );
    yield put(submitBasketMultiplePaymentSuccess(response));
  } catch (error) {
    yield put(submitBasketMultiplePaymentFailure(error, action?.errorCallBack));
  }
}

function* asyncGetBasketAllowedCardsRequest(action: any): any {
  try {
    const response = yield call(getBasketAllowedCards, action.basketid);
    yield put(getBasketAllowedCardsRequestSuccess(response));
  } catch (error) {
    yield put(getBasketAllowedCardsRequestFailure(error));
  }
}

function* asyncValidateAndGetBasketRequest(action: any): any {
  try {
    const validateResponse = yield call(validateBasket, action.action.basketId);
    yield put(validateBasketSuccess(validateResponse));
    const basketResponse = yield call(getBasket, action.action.basketId);
    yield put(getBasketRequestSuccess(basketResponse));
  } catch (error) {}
}

function* asyncGetCharityAmount(action: any): any {
  const callBack = action.payload.callBack;
  try {
    const basketId = action.payload.basketId;
    const body = { amount: action.payload.amount, id: action.payload.id };
    const response = yield call(getCharityRequest, basketId, body);
    yield put(getCharityRequestSuccess(response));
    callBack?.('success');
  } catch (error) {
    callBack?.('failure');
    yield put(getCharityRequestFailure(error));
  }
}
export function* checkoutSaga() {
  yield takeEvery(
    basketActionsTypes.GET_SINGLE_RESTAURANT_CALENDAR,
    asyncgetSingleRestaurantCalendarRequest,
  );
  yield takeEvery(
    basketActionsTypes.UPDATE_BASKET_TIME_WANTED,
    asyncUpdateBasketTimeWanted,
  );
  yield takeEvery(
    basketActionsTypes.DELETE_BASKET_TIME_WANTED,
    asyncDeleteBasketTimeWanted,
  );
  yield takeEvery(
    basketActionsTypes.UPDATE_BASKET_TIP_AMOUNT,
    asyncUpdateBasketTipAmount,
  );
  yield takeEvery(
    basketActionsTypes.UPDATE_BASKET_COUPON_CODE,
    asyncUpdateBasketCouponCode,
  );
  yield takeEvery(
    basketActionsTypes.REMOVE_BASKET_COUPON_CODE,
    asyncRemoveBasketCouponCode,
  );
  yield takeEvery(basketActionsTypes.VALIDETE_BASKET, asyncValidateBasket);
  yield takeEvery(
    basketActionsTypes.SUBMIT_BASKET_MULTIPLE_PAYMENT,
    asyncSubmitBasketMultiplePayment,
  );
  yield takeEvery(
    basketActionsTypes.SET_BASKET_DELIVERY_MODE_REQUEST,
    asyncSetBasketDeliveryModeRequest,
  );
  yield takeEvery(
    basketActionsTypes.SET_BASKET_DELIVERY_ADDRESS_REQUEST,
    asyncSetBasketDeliveryAddressRequest,
  );
  yield takeEvery(
    basketActionsTypes.GET_BASKET_ALLOWED_CARDS_REQUEST,
    asyncGetBasketAllowedCardsRequest,
  );
  yield takeEvery(
    basketActionsTypes.VALIDATE_AND_GET_BASKET_REQUEST,
    asyncValidateAndGetBasketRequest,
  );
  yield takeEvery(
    basketActionsTypes.CHARITY_ALLOWED_REQUEST,
    asyncGetCharityAmount,
  );
}
