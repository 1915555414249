import { useSelector } from 'react-redux';

const useCategoriesSelector = () => {
  const {
    categories: mCategories = {},
    loading: categoryLoader,
    error: categoryError,
  } = useSelector((state: any) => state.categoryReducer);
  const { categories, imagepath, singleusecategories } = mCategories || {};
  return {
    categories,
    imagepath,
    singleusecategories,
    categoryLoader,
    categoryError,
  };
};

export default useCategoriesSelector;
