import { useSelector } from 'react-redux';

const useBasketSelector = () => {
  const {
    loading: basketLoading,
    basket,
    validate,
    calendar = {},
    payment = {},
    basketType,
    orderSubmit = false,
    error,
    noTipSelected,
    addresses,
    showPromoMessage,
    showPromoDialog,
    signInNavigation,
    charityError,
    charity,
    charityAllowed,
    paymentInfo,
    submitBasketMultiplePaymentResponse,
    digitalPaymentInfo
  } = useSelector((state: any) => state.basketReducer);

  const { products = [] } = basket || {};
  const {
    allowedCards = {},
    defaultCards = {},
    billingSchemes = [],
    saveCreditCard = false,
  } = payment || {};
  const { data = {}, loading: allowedCardsLoading } = allowedCards || {};
  const { billingschemes: allowedBillingSchemes = [] } = data ?? {};

  let sum = 0;
  for (let i = 0; i < products.length; i++) {
    const product = products[i];
    sum += product.quantity;
  }
  const cartCount = sum;

  return {
    basket,
    payment,
    allowedCards,
    defaultCards,
    billingSchemes,
    calendar,
    basketLoading,
    allowedBillingSchemes,
    orderSubmit,
    error,
    products,
    cartCount,
    allowedCardsLoading,
    saveCreditCard,
    addresses,
    charityError,
    charity,
    paymentInfo,
    basketType,
    noTipSelected,
    showPromoMessage,
    showPromoDialog,
    signInNavigation,
    charityAllowed,
    validate,
    submitBasketMultiplePaymentResponse,
    digitalPaymentInfo
  };
};
export default useBasketSelector;
