import { useMemo } from 'react';
import { BasketProduct } from '../types/olo-api';
import useCategoriesSelector from './reduxStateHooks/useCategoriesSelector';
import useBasketSelector from './reduxStateHooks/useBasketSelector';

const useSalsaBarCalculation = () => {
  const {categories}=useCategoriesSelector();
  const {basket}=useBasketSelector()


  const findMatchingCategories = (products: BasketProduct[]) => {


    const orderItemsCategories: any[] = [];

    products?.forEach((product) => {
      const matchingCategory = categories?.find((category: { products: { id: any; }[]; }) =>
        category?.products?.some(
          (categoryProduct: { id: any; }) => categoryProduct?.id === product?.productId
        )
      );



      if (matchingCategory) {
        const matchedCategoryProduct = matchingCategory?.products.find(
          (categoryProduct: { id: any; }) => categoryProduct?.id === product?.productId
        );

        const newCategory = {
          ...matchingCategory,
          products: [matchedCategoryProduct],
          originalProduct: product,
        };



        orderItemsCategories.push(newCategory);
      }
    });

    return orderItemsCategories;
  };

  const calculateSalsas = (categories: any[]) => {
    return categories
      .map((category) => {
        const limitSalsa =
          category?.metadata?.find((meta: { key: string; }) => meta.key === 'Limit-salsa')?.value || 0;

        return limitSalsa * (category?.originalProduct?.quantity || 0);
      })
      .reduce((total, salsas) => total + salsas, 0);
  };

  const getSalsaBarProductIds = (categories: any[]) => {
    const salsaBarCategory = categories?.find(
      (category) => category.name.toLowerCase().trim() === 'salsa bar & utensils'
    );

    if (!salsaBarCategory || !salsaBarCategory.products) {
      return [];
    }

    return salsaBarCategory.products.map((product: { id: any; }) => product.id);
  };

  return { findMatchingCategories, calculateSalsas, getSalsaBarProductIds };
};

export default useSalsaBarCalculation;
